.layout {
  flex-grow: 1;
  display: grid;
  padding: 2.4rem;
  margin: 0px;
  height: calc(100vh - 64px);
  grid-auto-rows: min-content;
  overflow-y: auto;
  overflow-y: overlay;
}
